import UserContext from 'Contexts/User';

import coinParliament, { functions } from '../../../firebaseCoinParliament';
import React, { useContext, useEffect, useState } from 'react'
import { Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PAX from '../../../assets/logos/PAX.png';
import VTE from '../../../assets/logos/VTE.png';
import { httpsCallable } from 'firebase/functions';
import axios from 'axios';
import styled from 'styled-components';
import { getAllVteValue, getVteValue, passNameAndGetData,transferVTE } from 'common/models/utils';
import WaitForResponse from 'Components/Waitforitloader/WaitForResponse';

const ButtonStyle = styled.button`
  width: 100px;
  padding: 5px;
  border: none;
  border-radius: 7px; 
  color:white;
  margin-top: 10px;
`;

const LinkStyle = styled.p`  
  padding: 5px;  
  color:#6352e8;
  margin-top: 10px;
  text-decoration: underline;
  cursor:pointer;
`;
const SelectDiv = styled.select`
  width: 130px;
  padding: 8px;
  border: none;
  border-radius: 5px;
  // background-color: #f1f1f1;
  border:2px solid #f1f1f1;
  // margin-bottom: 10px;
`;

const InputBox = styled.input`
  width: 130px;
  padding: 8px;
  border: none;
  border-radius: 5px;
  // background-color: #f1f1f1;
  border:2px solid #f1f1f1;
  // margin-bottom: 10px;
`;

const Logo = (symbol:any) => {
  return (
    <>
    
      {symbol  && <Image
        src={process.env.PUBLIC_URL + `/images/logos/${symbol?.symbol?.toUpperCase()}.svg`}
      style={{
        // margin: "0 auto",
        width: `${symbol !== "ETH" ? "40px" : "35px"}`,
        height: `${symbol !== "ETH" ? "40px" : "40px"}`,
      }}
      onError={(e) =>
        ((e.target as HTMLImageElement).src = "/images/no_logo.png")
      }
      />}
    </>
  );
};

function WalletDetails({ ActiveProject }: {ActiveProject?:any}) {

  const [pendingAmount, setPendingAmount] = useState({
    ETH: 0,
    BNB: 0,
    MATIC: 0,
    USDT: 0,
    VTE: 0,
  })
  const userIds = JSON.parse((localStorage.getItem('userId') || "{}"));
  let navigate = useNavigate(); 
  const { userInfo } = useContext(UserContext);
  const [modalShow, setModalShow] = React.useState(false);
  const handleClose = () => setModalShow(false);
  const [completedPAX, setCompletePax] = useState(0);
  const [errorValue, setErrorValue] = useState("");
  const [VTETransfer, setVTETransfer] = useState(false);
  const [VETValue, setVETValue] = useState(0);
  const [transferVteAmount, setTransferVteAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const getCompletedPaxCountOfUserData = httpsCallable(functions, "getCompletedPaxCountOfUser");
  const [slectParliment, setSlectParliment] = useState("");
  const [allProjectName, setAllProjectName] = useState([
    { id: 0, name: "Select Parliament" },
    { id: 1, name: "Coin Parliament" },
    { id: 2, name: "Stock Parliament" },
    { id: 3, name: "Sport Parliament" },
    { id: 4, name: "Voting Parliament" },
]);
  const getCompletedPaxCountOfUser = async (ActiveProject:string) => {
    if (userInfo?.uid && ActiveProject=="Coin Parliament") {
      const data = {
        userId: userIds?.coin
      }
      axios.post(`${process.env.REACT_APP_COIN_API}/getCompletedPaxCountOfUser`, { data }).then((res) => {        
        if (Object.keys(res?.data?.result?.result || {}).length) setCompletePax(res?.data?.result?.result || 0);
      }).catch((err) => {
        
      });
    }else{
      setCompletePax(0)
    }
  };

  // const getVteValue = async() => {
  //   const referUser = await coinParliament.firestore().collection('users').where('email', '==', userInfo?.email).get();
  //   if (!referUser.empty) {
  //     referUser.forEach((doc: any) => {        
  //       console.log(doc.data(), "doc.data()")

  //       setVETValue(doc.data()?.rewardStatistics?.rewardedVTE || 0)
  //     });
  //   }
  // }
  

  useEffect(() => {
    if (userInfo?.email) {      
      getCompletedPaxCountOfUser(ActiveProject)
      getAllVteValue(userInfo?.email,setVETValue)
    } else {
      setVETValue(0)
      setCompletePax(0)
    }
    setTransferVteAmount("")
    setSlectParliment("")
  }, [userInfo?.uid, ActiveProject,userInfo?.rewardStatistics?.diamonds]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setErrorValue("");
    // Regular expression to allow only positive decimal numbers greater than 0
    if (/^[1-9]\d*$/.test(inputValue) || inputValue === "") {
      setTransferVteAmount(inputValue);
      console.log(inputValue,"inputValueinputValue")
    }
  };
  const TransforVteToOther = async ()=>{        
    if(!slectParliment || slectParliment =="Select Parliament"){
        setErrorValue("Please select parliament");
        return;
    }
    if(!transferVteAmount){
        setErrorValue("Please enter VTE coin");
        return;
    }
    if(Number(transferVteAmount) > Number(VETValue)){
        setErrorValue("You don't have enough VTE coin");
        return;
    }
    setIsLoading(true);
    setErrorValue("");
    const foundItem = await passNameAndGetData(ActiveProject)    
    const baseData = {
      userId: `${foundItem?.userId}`,
      email: `${userInfo?.email}`,
      VTEAmount: Number(transferVteAmount),      
    }   
    const apiUrls: Record<string, string | undefined> = {
      "Coin Parliament": process.env.REACT_APP_COIN_API + "transferVTEToVoteToEarn",      
      "Stock Parliament": process.env.REACT_APP_STOCK_API + "transferVTEToVoteToEarn",
      "Sport Parliament": process.env.REACT_APP_SPORT_API + "transferVTEToVoteToEarn",
      "Voting Parliament": process.env.REACT_APP_VOTING_API + "transferVTEToVoteToEarn",
      "VoteToEarn Parliament": process.env.REACT_APP_V2E + "transferVTEToGivenParliament",
    };

    if (ActiveProject === "VoteToEarn Parliament") {
      const voteToEarnItem = await passNameAndGetData(slectParliment);
      if (!voteToEarnItem) return;
  
      const voteToEarnData = {
        userId: `${userInfo?.uid}`,
        email: `${userInfo?.email}`,
        VTEAmount: Number(transferVteAmount),
        parliament: voteToEarnItem?.id,
      };
  
      transferVTE(apiUrls[ActiveProject]!, voteToEarnData, setVTETransfer,setIsLoading);
    } else {
      transferVTE(apiUrls[ActiveProject]!, baseData, setVTETransfer,setIsLoading);
    }       
  }

console.log(slectParliment,"setSlectParliment")
  return (
    <div>
      <>
        {isLoading && <>
        <WaitForResponse/>
        </>}
          <div className='d-flex justify-content-center align-items-center'
            style={{
              // color: "white"
            }}
          >
            <div className='mt-3'>
            <p className='mx-3 text-center my-4' style={{
                fontSize: "18px",
                fontWeight: 'bold'
              }}>{"All Wallet Details".toLocaleUpperCase()}</p>
              <p className='mx-3' style={{
                fontSize: "16px"
              }}>{"YOUR REWARD RECEIVE AMOUNT".toLocaleUpperCase()}</p>
              <div className='d-flex justify-content-around align-items-center my-2'
                style={{
                  background: "white",
                  borderRadius: "5px",
                  padding: "8px 8px",
                }}
              >
                <div className='d-flex align-items-center'>
                  <img src={PAX} alt=""
                    style={{
                      // margin: "0 auto",
                      width: "40px",
                      height: "40px",
                    }}
                  />
                  <p className=''
                    style={{
                      marginLeft: `${"23px"}`,
                      color: "black",
                    }}
                  >PAX</p>
                </div>
                <p className=''
                  style={{
                    color: "#6352E8",
                  }}
                >{completedPAX}</p>
            </div>
            {!VTETransfer && <div className='d-flex justify-content-around align-items-center my-2'
              style={{
                background: "white",
                borderRadius: "5px",
                padding: "8px 8px",
              }}
            >
              <div className='d-flex align-items-center'>
                <img src={VTE} alt=""
                  style={{
                    // margin: "0 auto",
                    width: "40px",
                    height: "40px",
                  }}
                />
                <p className=''
                  style={{
                    marginLeft: `${"23px"}`,
                    color: "black",
                  }}
                >VTE</p>
              </div>
              <p className=''
                style={{
                  color: "#6352E8",
                }}
              >{VETValue}</p>
            </div>}  
            {             
              <div className='d-flex justify-content-center'>
                <LinkStyle
                onClick={() => {
                  navigate("/profile/wallet");
                }}
                >Check Other Wallet Details</LinkStyle>
              </div>
            }                      
              <p className='mx-3' style={{
                fontSize: "18px",
                margin: "20px 0px 10px 0px"
              }}>{"Your Referral Receive Amount".toLocaleUpperCase()}</p>

              {Object?.keys(pendingAmount)?.length ?
                <div className='d-flex flex-column'>
                  {Object?.keys(pendingAmount)?.map((value, index) => {
                    if (value == "ETH" || value == "BNB" || value == "MATIC" || value == "USDT") {
                      console.log(value,"valuevaluevalue")
                      return <>

                        <div className='d-flex justify-content-around align-items-center my-2 '
                          style={{
                            background: "white",
                            borderRadius: "5px",
                            padding: "8px",
                          }}
                        >
                          <div className='d-flex align-items-center'>
                            <Logo
                              symbol={value}

                            />
                            <p className=''
                              style={{
                                marginLeft: `${value == "ETH" ? "23px" : "20px"}`,
                                color: "black",
                              }}
                            >{value == "MATIC" ? "POL" : value} </p>
                          </div>

                          {/* @ts-ignore */}
                          <p className=''
                            style={{
                              color: "#6352E8",
                            }}
                          >{pendingAmount[value] != 0 ? Number(pendingAmount[value])?.toFixed(4) : 0}</p>
                        </div>
                      </>
                    }

                  })
                }
                                
                </div>
                :
                <div className="d-flex justify-content-center" >
                  <p className='mt-3'>Pending Payment Amount is not found</p>
                </div>
              }
            </div>            
          </div>
      </>
    </div>
  )
}

export default WalletDetails