import CoinParliament from '../../firebaseCoinParliament';
import SportParliament from '../../firebaseSportParliament';
import StockParliament from '../../firebaseStockParliament';
import VotingParliament from '../../firebaseVotingParliament';
import { collection, query, where, getDocs } from "firebase/firestore";
import {V2EParliament} from '../../firebase';
import NotificationContext, { ToastType } from "../../Contexts/Notification";
import { httpsCallable } from 'firebase/functions';
import { showToast } from 'App';
import axios from 'axios';

export interface Callback<T> {
    successFunc: (params: T) => void;
    errorFunc: (error: Error, params?: T) => void;
}
interface payloadData {
    userId:string,
    email:string,
    VTEAmount:number,
    parliament?:number
}

export const transferVTE = async (apiUrl: string, data: payloadData, setVTETransfer: any,setIsLoading:any) => {
    try {
        const response = await axios.post(apiUrl, { data });
        console.log(response.data.status, "check data");

        setVTETransfer(false);
        setIsLoading(false);

        if (response?.data?.status === false) {
            showToast(response.data.message, ToastType.ERROR);
        }
    } catch (err) {
        console.log(err);
        setIsLoading(false);
    }
};

export const SetWalletData = async (data: any, setEditEnable: any,setIsLoading:any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_V2E}updateSettingsForParliament`, { data });
        console.log(response.data.status, "check data");

        setEditEnable(false);
        setIsLoading(false);

        if (response?.data?.status === false) {
            showToast(response.data.message, ToastType.ERROR);
        }
        if (response?.data?.status === true) {
          showToast("Address Added Successfully", ToastType.SUCCESS);
        }
    } catch (err) {
        console.log(err);
        setIsLoading(false);
        setEditEnable(false);
    }
};


export const passNameAndGetData = async (name:string) => {
const userIds = JSON.parse((localStorage.getItem('userId') || "{}"));
const allData = [
    { id: 0, name: "VoteToEarn Parliament",userId:userIds.V2E },
    { id: 1, name: "Coin Parliament" ,userId:userIds.coin},
    { id: 2, name: "Stock Parliament",userId:userIds.stock },
    { id: 3, name: "Sport Parliament",userId:userIds.sport },
    { id: 4, name: "Voting Parliament",userId:userIds.voting },
]
const foundItem = allData.find(item => item.name == name);
console.log(name,foundItem,"foundItemfoundItem")
return foundItem
}


export const getVteValue = async (name: string, email: string, setVETValue: any) => {
    const parliamentMap: Record<string, any> = {
      "Coin Parliament": CoinParliament,
      "Stock Parliament": StockParliament,
      "Sport Parliament": SportParliament,
      "Voting Parliament":VotingParliament,
      "VoteToEarn Parliament":V2EParliament
    };
  
    const firestoreInstance = parliamentMap[name];
  
    if (!firestoreInstance) return; // Exit if name is not in the map
  
    const referUser = await firestoreInstance.firestore().collection("users").where("email", "==", email).get();
  
    if (!referUser.empty) {
      referUser.forEach((doc: any) => {
        console.log(doc.data(), "doc.data()");
        setVETValue(doc.data()?.rewardStatistics?.diamonds || 0);
        // setVETValue(doc.data()?.rewardStatistics?.rewardedVTE || 0);
      });
    }
  };

  export const getAllVteValue = async (email: string, setVETValue: any) => {
    let TotalVte = 0;
  
    const parliaments = [
      CoinParliament,
      StockParliament,
      SportParliament,
      VotingParliament,
      V2EParliament,
    ];
  
    const queries = parliaments.map((parliament) =>
      parliament.firestore().collection("users").where("email", "==", email).get()
    );
  
    const results = await Promise.all(queries);
  
    results.forEach((querySnapshot) => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          console.log(doc.data(), "doc.data()");
          TotalVte += doc.data()?.rewardStatistics?.diamonds || 0;          
        });
      }
    });
  
    setVETValue(TotalVte);
  };
  

export const fetchPrices = async () => {
  try {
    // CoinGecko API IDs
    const coinIds = {
      ETH: "ethereum",
      BNB: "binancecoin",
      POL: "polygon-pos",
    };       
    const response = await fetch("https://min-api.cryptocompare.com/data/pricemulti?fsyms=ETH,BNB,POL&tsyms=USD");       
    const data = await response.json();  
    console.log(data,"datadatadatadata")  
    const updatedPrices = {
      ETH: { price: data.ETH?.USD?.toString() || "N/A" },
      BNB: { price: data.BNB?.USD?.toString() || "N/A" },
      MATIC: { price: data.POL?.USD?.toString() || "N/A" },
    };   
    localStorage.setItem("CoinsPrice", JSON.stringify(updatedPrices));
  } catch (err) {
    // setError("Failed to fetch prices");
    console.log(err,"Failed to fetch prices")
  } 
};

export const getUnselectCoinName = async (name: string, email: string, setCoinsList: any,setSelectDBUserDetails:any) => {
    const parliamentMap: Record<string, any> = {
      "Coin Parliament": CoinParliament,
      "Stock Parliament": StockParliament,
      "Sport Parliament": SportParliament,
      "Voting Parliament":VotingParliament,
      "VoteToEarn Parliament":V2EParliament
    };
  
    const firestoreInstance = parliamentMap[name];
  
    if (!firestoreInstance) return; // Exit if name is not in the map
  
    const referUser = await firestoreInstance.firestore().collection("users").where("email", "==", email).get();
  
    let allList = [
      {status: "Active", symbol: "ETH", id: "2",name: "Ethereum",},
      {id: "3",status: "Active",name: "Binance",symbol: "BNB",},
      {symbol: "MATIC", status: "Active", name: "Polygon",id: "4",},
      {symbol: "TON", status: "Active", name: "TON", id: "-239",},
    ];
      

    if (!referUser.empty) {
      referUser.forEach((doc: any) => {
        console.log(doc.data(), "doc.data()");
        const userInfo = doc.data();
        setSelectDBUserDetails(userInfo)
        const uniqueNamesArray2 = allList.filter(
            (obj2) =>
              // @ts-ignore
              userInfo?.wellDAddress ?
              // @ts-ignore
              !userInfo?.wellDAddress?.some((obj1) => obj1?.coin === obj2?.symbol):allList
          );
          // @ts-ignore
          setCoinsList(uniqueNamesArray2);
          
console.log(uniqueNamesArray2,"uniqueNamesArray2")
      });
    }
  };
  
  