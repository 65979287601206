import React, { useContext, useEffect, useState } from "react";
import { Buttons } from "../../Atoms/Button/Button";
import { useTranslation } from "../../../common/models/Dictionary";

import styled from "styled-components";

// import { texts } from "Components/LoginComponent/texts";
// import SelectTextfield from "Components/Forms/SelectTextfield";
import firebase from "firebase/compat";

import { Col, Form, FormControl, Modal, Row } from "react-bootstrap";
import UserContext from "../../../Contexts/User";
import { useNavigate } from "react-router-dom";
import WalletValidation from "./WalletValidation";
import WalletInfo from "./WalletInfo";
import comingSoon from 'assets/images/comingSoon.jpg'
import Tabs from "../Tabs";
import PendingBalance from "./PendingBalance";
import ReceivedBalance from "./ReceivedBalance";
import WalletSetting from "./WalletSetting";
// var WAValidator = require('wallet-address-validator');

const Errorsapn = styled.span`
  color:red;
`;
const SelectDiv = styled.select`
  width: 150px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #f1f1f1;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Wallet = () => {
    const { userInfo } = useContext(UserContext);
    let navigate = useNavigate();
    // const [modleShow, setModleShow] = useState(false)
    const [mfaLogin, setMfaLogin] = useState(false)
    const [ActiveProject, setActiveProject] = useState("VoteToEarn Parliament")
    const [regexList, setRegexList] = useState({
        bitcoin: "/^(1|3)[a-km-zA-HJ-NP-Z1-9]{25,34}$/",
        ethereum: "/^0x[a-fA-F0-9]{40}$/",
    })


    const [allProjectName, setAllProjectName] = useState([
        { id: 1, name: "VoteToEarn Parliament" },
        { id: 2, name: "Coin Parliament" },
        { id: 3, name: "Voting Parliament" },
        { id: 4, name: "Sport Parliament" },
        { id: 5, name: "Stock Parliament" },
    ]);
    const [selectTab, setSelectTab] = useState("Received")

    console.log(selectTab,"selectTabselectTab1231")

    return (
        <>
            <>
            {/* <Container> */}
            {/* {mfaLogin && */}
                {/* <WalletInfo /> */}
            {/* } */}


            {/* {!mfaLogin &&
                <WalletValidation
                    setMfaLogin={setMfaLogin}
                />
            } */}


            {/* <div>
                <Modal
                    className=""
                    show={
                        modleShow
                    } onHide={handleModleClose}

                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    style={{ backgroundColor: "rgb(0 0 0 / 80%)", zIndex: "2200" }}
                // @ts-ignore
                // contentClassName={"modulebackground ForBigNft"}
                >
                    <div className="d-flex justify-content-end">
                    </div>
                    <Modal.Body
                    >
                        <div className="d-flex flex-column align-items-center">
                            <strong className="" style={{
                                fontSize: "20px",
                                textAlign: "center"
                            }}>Enable your Two-factor authentication</strong>
                            <p className="mt-3 tex-center"
                                style={{
                                    textAlign: "center"
                                }}
                            >Befor update your wallet info Please Enable your Two-factor authentication</p>
                        </div>
                    </Modal.Body>
                    < div className="d-flex justify-content-center " >
                        <Buttons.Primary
                            // disabled={selectPayment == 0}
                            // className="mx-2"
                            onClick={() => {
                                navigate("/profile/password")
                            }}
                        >Enable</Buttons.Primary>
                    </div >
                </Modal>
            </div> */}
            </>

            <div className="d-flex justify-content-center align-items-center flex-column"            
            >                
                <div className="w-100 mt-2">
                    <Tabs
                        defaultActiveKey={selectTab}
                        activeKey={selectTab}
                        id="Wallet"
                        onSelect={(key: string) => {
                            setSelectTab(key)                            
                        }}
                        tabs={[                        
                        {
                            eventKey: "Received",
                            title: "Received Balance",
                            pane: (
                                <>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <SelectDiv
                                            value={ActiveProject}
                                            onChange={(e) => {
                                                setActiveProject(e.target.value)
                                                // getCompletedPaxCountOfUser()
                                                // getVteValue()
                                            }}
                                        >
                                            {allProjectName?.map((projectName) => {
                                                return (<option key={projectName.id}
                                                    // onClick={() => {
                                                    //     setActiveProject(projectName.name)
                                                    // }}
                                                    value={projectName?.name}
                                                >
                                                    {projectName?.name}
                                                </option>)
                                            })}                        
                                        </SelectDiv>
                                    </div> 
                                    <ReceivedBalance ActiveProject={ActiveProject} />
                                </>
                            ),
                        },
                        {
                            eventKey: "Pending",
                            title: "Pending Balance",
                            pane: (
                                <>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <SelectDiv
                                            value={ActiveProject}
                                            onChange={(e) => {
                                                setActiveProject(e.target.value)
                                            }}
                                        >
                                            {allProjectName?.map((projectName) => {
                                                return (<option key={projectName.id}
                                                    // onClick={() => {
                                                    //     setActiveProject(projectName.name)
                                                    // }}
                                                    value={projectName?.name}
                                                >
                                                    {projectName?.name}
                                                </option>)
                                            })}                                            
                                        </SelectDiv>
                                    </div> 
                                    < PendingBalance ActiveProject={ActiveProject} />
                                </>
                            ),
                        },      
                        {
                            eventKey: "Setting",
                            title: "Setting",
                            pane: (
                                <>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <SelectDiv
                                            value={ActiveProject}
                                            onChange={(e) => {
                                                setActiveProject(e.target.value)
                                            }}
                                        >
                                            {allProjectName?.map((projectName) => {
                                                return (<option key={projectName.id}
                                                    // onClick={() => {
                                                    //     setActiveProject(projectName.name)
                                                    // }}
                                                    value={projectName?.name}
                                                >
                                                    {projectName?.name}
                                                </option>)
                                            })}                                            
                                        </SelectDiv>
                                    </div> 
                                    < WalletSetting ActiveProject={ActiveProject} selectTab={selectTab}/>
                                </>
                            ),
                        },      
                    ]}
                    />
                    
                </div>                
            </div>


        </>
    );
};

export default Wallet;
