import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "./index.css";
import axios from "axios";
import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";

axios.defaults.baseURL = process.env.REACT_APP_API;

// @ts-ignore
window.changeLanguage = (lang: string) => {
  const langDetector = document.getElementById("lang-detector");
  if (langDetector) {
    (langDetector as HTMLInputElement).value = lang;
    langDetector?.dispatchEvent(new CustomEvent("change"));
  }
};

const root = ReactDOM.createRoot(
  document.getElementById('app') as HTMLElement
);
root.render(
  <div>
    <TonConnectUIProvider
          manifestUrl="https://coin-parliament-staging.web.app/tonconnect-manifest.json"          
          uiPreferences={{ theme: THEME.DARK }}              
      >
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </TonConnectUIProvider>
  </div>
);

reportWebVitals();

const sw = "../firebase-messaging-sw.js";
// process.env.NODE_ENV === "production"
//   ? `${process.env.REACT_APP_SITE_URL}wp-content/plugins/coin-parliament/public/firebase-messaging-sw.js`
//   : "../firebase-messaging-sw.js";
// console.log(sw)
if ("serviceWorker" in navigator) {
  navigator?.serviceWorker
    .register(sw)
    .then(function (registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}
