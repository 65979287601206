import React, { useContext, useEffect, useState } from 'react'
import PAX from '../../../assets/logos/PAX.png';
import { Form, Image, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import UserContext from 'Contexts/User';
import axios from 'axios';
import { showToast } from 'App';
import { ToastType } from 'Contexts/Notification';
import SelectTextfield from 'Components/Forms/SelectTextfield';
import styled from 'styled-components';
import moment from 'moment';
import '../style.css';
import { Buttons } from 'Components/Atoms/Button/Button';
import WalletValidation from './WalletValidation';
import { getUnselectCoinName, passNameAndGetData, SetWalletData } from 'common/models/utils';
import { UserProps } from 'common/models/User';

const Errorsapn = styled.span`
  color: red;
`;
const RemoveButton = styled.button`
  width: 45px;
  height: 45px;
  border: none;
  font-size: 30px;
  background: #4f36c7;
  color: white;
`;

function WalletSetting({ ActiveProject,selectTab }: { ActiveProject: any,selectTab:any }) {
  const { userInfo, user } = useContext(UserContext);
  const [tooltipShowCard, setTooltipShowCard] = React.useState(false);
  const [tooltipShowPax, setTooltipShowPax] = React.useState(false);
  const [walletDetailsObj, setWalletDetailsObj] = useState([
    {
      coin: "",
      address: "",
    },
  ]);
  const [coinList, setCoinsList] = useState([]);
  const [tooltipShow, setTooltipShow] = React.useState(false);
  const [selectRadio, setSelectRadio] = useState<string>("ONDEMAND");
  const [savePaymentMethod, setSavePaymentMethod] = useState(false);
  const [savePaxAddress, setSavePaxAddress] = useState(false);
  const [tooltipShow2, setTooltipShow2] = React.useState(false);
  const [saveAddress, setSaveAddress] = useState(false);
  const [timeError, setTimeError] = useState("");
  const [amountError, setAmountError] = useState("");
  const [limitType, setLimitType] = useState<string>("");
  const [editPaxAddress, setEditPaxAddress] = useState(false);
  const [editCardAddress, setEditCardAddress] = useState(false);
  const [saveCardAddress, setSaveCardAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addType, setAddType] = React.useState("");
  const [selectDBUserDetails, setSelectDBUserDetails] = useState<UserProps>();

  const [walletDetails, setWalletDetails] = useState({
    coin: "",
    address: "",
  });
  const [timeAmount, setTimeAmount] = useState({
    time: "",
    amount: "",
  }); 
  const [paxDetails, setPaxWalletDetails] = useState({
    coin: "BNB",
    address: "",
  });
  const [CardDetails, setCardWalletDetails] = useState({
    coin: "MATIC",
    address: "",
  });
  const [errorValue, setErrorValue] = useState({
    coinError: "",
    walletError: "",
  });

  const [PaxErrorValue, setPaxErrorValue] = useState({
    coinError: "",
    walletError: "",
  });
  const [CardErrorValue, setCardErrorValue] = useState({
    coinError: "",
    walletError: "",
  });

  const [timeValue, setTimeValue] = useState("");
  const [amountValue, setAmountValue] = useState("");
  const [timeType, setTimeType] = useState<string>("time");
  const [tooltipShow5, setTooltipShow5] = React.useState(false);
  const [modleShow, setModleShow] = useState(false);
  const handleModleClose = () => setModleShow(false);
  const handleModleShow = () => setModleShow(true);
  const [mfaLogin, setMfaLogin] = useState(false);


  console.log(selectDBUserDetails,"wellDAddresswellDAddress")
  useEffect(() => {
    if (Array.isArray(selectDBUserDetails?.wellDAddress) == true) {
      // @ts-ignore
      setWalletDetailsObj(selectDBUserDetails?.wellDAddress || []);
    } else {
      // @ts-ignore
      setWalletDetailsObj([]);
    }
    if (typeof selectDBUserDetails?.paxAddress === "object") {
      // @ts-ignore
      setPaxWalletDetails(selectDBUserDetails?.paxAddress || {});
      setEditPaxAddress(selectDBUserDetails?.paxAddress?.address ? false : true);
    } else {
      // @ts-ignore
      setPaxWalletDetails({});
      setEditPaxAddress(true);
    }
    // @ts-ignore
    if (typeof selectDBUserDetails?.cardAddress === "object") {
      // @ts-ignore
      setCardWalletDetails(selectDBUserDetails?.cardAddress || {});
      setEditCardAddress(selectDBUserDetails?.cardAddress?.address ? false : true);
    } else {
      // @ts-ignore
      setCardWalletDetails({});
      setEditCardAddress(true);
    }

    console.log(selectDBUserDetails?.wellDAddress, "selectDBUserDetails?.wellDAddress");
    setSelectRadio(selectDBUserDetails?.referalReceiveType?.name || "ONDEMAND");
    setDefaultValue();
  }, [selectDBUserDetails]);

  useEffect(() => {
    if(userInfo?.email){
      getUnselectCoinName(ActiveProject,userInfo?.email,setCoinsList,setSelectDBUserDetails)
    }
  }, [ActiveProject,selectTab]);

console.log(selectTab,"selectTabselectTab")
  const setDefaultValue = () => {
    // @ts-ignore
    if (selectDBUserDetails?.referalReceiveType?.limitType == "TIME") {
      // @ts-ignore
      setLimitType(selectDBUserDetails?.referalReceiveType?.limitType);
      // @ts-ignore
      setTimeValue(selectDBUserDetails?.referalReceiveType?.days);
    }
    // @ts-ignore
    if (selectDBUserDetails?.referalReceiveType?.limitType == "AMOUNT") {
      // @ts-ignore
      setLimitType(selectDBUserDetails?.referalReceiveType?.limitType);
      setAmountValue(selectDBUserDetails?.referalReceiveType?.amount);
    }
    // @ts-ignore
    if (selectDBUserDetails?.referalReceiveType?.limitType == "ANY_OF_THEM") {
      // @ts-ignore
      setLimitType(selectDBUserDetails?.referalReceiveType?.limitType);
      setTimeAmount({
        amount: selectDBUserDetails?.referalReceiveType?.amount || "",
        time: selectDBUserDetails?.referalReceiveType?.time
          ? selectDBUserDetails?.referalReceiveType?.time
          : "",
      });
    }
    setTimeType(selectDBUserDetails?.referalReceiveType?.amount ? "amount" : "time");
  };

  const hideError = () => {
    setTimeError("");
    setAmountError("");
    setTimeAmount({ time: "", amount: "" });
  };
  let navigate = useNavigate();
  

  const handleChangeValue = (e: any, type: string) => {
    let name = e.target.name;
    let value = e.target.value;
    if (type == "paxDetails") {
      setPaxWalletDetails({
        coin: "BNB",
        address: value,
      });
      setPaxErrorValue({
        coinError: "",
        walletError: "",
      });
    }
    if (type == "CardDetails") {
      setCardWalletDetails({
        coin: "MATIC",
        address: value,
      });
      setPaxErrorValue({
        coinError: "",
        walletError: "",
      });
    }
    if (type == "walletDetails") {
      setWalletDetails({ ...walletDetails, [name]: value });
      setErrorValue({
        coinError: "",
        walletError: "",
      });
    }
  };

  const updateAddress = async () => {
    if (!walletDetails.coin) {
      setErrorValue({ ...errorValue, coinError: "Please select coin" });
    } else if (!walletDetails.address) {
      setErrorValue({ ...errorValue, walletError: "Enter Wallet Address" });
    }else if (walletDetails.coin!=="TON" && walletDetails.address) {
      setSaveAddress(true);
      const validate = await validateAddress(
        walletDetails.address,
        walletDetails.coin,
        "forWallet"
      );
      if (validate) {
        setSaveAddress(false);
      } else {
        setErrorValue({
          ...errorValue,
          walletError: "Enter Valid Wallet Address ",
        });
        setSaveAddress(false);
      }
    }else if(walletDetails.coin=="TON" && walletDetails.address){      
      // Regex pattern for basic TON address format
      const tonAddressPattern = /^[A-Za-z0-9_-]{48}$/;
      const AddressCheck= tonAddressPattern.test(walletDetails.address);

      console.log(AddressCheck, walletDetails.address,"AddressCheck")
      if(!AddressCheck){
        setErrorValue({
          ...errorValue,
          walletError: "Enter Valid Wallet Address ",
        });
      }else{
        setWalletDetails({ coin: "", address: "" });
          setWalletDetailsObj([
            ...walletDetailsObj,
            {
              address: walletDetails.address,
              coin: walletDetails.coin,
            },
          ]);
          // @ts-ignore
          const coinsLists = coinList.filter((item) => item?.symbol !== walletDetails.coin);
          setCoinsList(coinsLists);
      }    
  }
  };

  const validateAddress = async (
    inputAddress: string,
    type: string,
    checktype: string
  ) => {
    return axios
      .get(`https://api.blockcypher.com/v1/${"eth"}/main/addrs/${inputAddress}`)
      .then(async (response) => {
        const { error } = response.data;
        console.log(response, "geterror");

        if (!error && checktype == "forWallet") {
          setWalletDetails({ coin: "", address: "" });
          setWalletDetailsObj([
            ...walletDetailsObj,
            {
              address: inputAddress,
              coin: type,
            },
          ]);
          // @ts-ignore
          const coinsLists = coinList.filter((item) => item?.symbol !== type);
          setCoinsList(coinsLists);     
        }
        const foundItem = await passNameAndGetData(ActiveProject)
        // Cehck pax value        
        const data= {
          email: userInfo?.email,
          addressType: checktype == "forCard"? 1:checktype == "forPax"?2:3,
          updateArray: [
              {
                  address: inputAddress,
                  coin: type,
              },                   
          ],
          parliament: foundItem?.id,
          action: "add",
      }

        if (checktype == "forPax") {
          SetWalletData(data, setEditPaxAddress,setIsLoading);                         
        }

        if (checktype == "forCard") {         
          SetWalletData(data, setEditCardAddress,setIsLoading);
          
        }
        if (error) {
          return false;
        } else {
          return true;
        }
      })
      .catch(() => {
        return false;
      });
  };


  const UpdateFunction = (valueType?:any) => {
    console.log(addType,"addType")
    handleModleClose();
    
    if (valueType == "UPDATESETTING") {
      selectSendType();
    }    
    if (valueType == "PAXADDADDERS") {
      updatePaxAddress();
    }
    if (valueType == "CARDADDADDERS") {
      updateCardAddress();
    }
  };

  const updatePaxAddress = async () => {
    if (!paxDetails.coin) {
      setPaxErrorValue({ ...PaxErrorValue, coinError: "select coin" });
    } else if (!paxDetails.address) {
      setPaxErrorValue({ ...PaxErrorValue, walletError: "Enter Pax Address" });
    } else if (paxDetails.address) {
      setSavePaxAddress(true);
      const validate = await validateAddress(
        paxDetails.address,
        paxDetails.coin,
        "forPax"
      );
      if (validate) {
        setSavePaxAddress(false);
      } else {
        setPaxErrorValue({
          ...PaxErrorValue,
          walletError: "Enter Valid Pax Address ",
        });
        setSavePaxAddress(false);
      }
    }
  };

  const updateCardAddress = async () => {
    if (!CardDetails.coin) {
      setCardErrorValue({ ...CardErrorValue, coinError: "select coin" });
    } else if (!CardDetails.address) {
      setCardErrorValue({
        ...CardErrorValue,
        walletError: "Enter Card Address",
      });
    } else if (CardDetails.address) {
      setSaveCardAddress(true);
      const validate = await validateAddress(
        CardDetails.address,
        CardDetails.coin,
        "forCard"
      );
      if (validate) {
        setSaveCardAddress(false);
      } else {
        setCardErrorValue({
          ...CardErrorValue,
          walletError: "Enter Valid Card Address ",
        });
        setSaveCardAddress(false);
      }
    }
  };

  const selectSendType = async () => {
    console.log(ActiveProject,"foundItemfoundItem")
    setTimeError("");
    setAmountError("");
    let errorCount = 0;
    const allwalletData = [...walletDetailsObj];
    const foundItem = await passNameAndGetData(ActiveProject)
    if (selectRadio === "LIMIT" && limitType != "") {
      if (
        selectRadio === "LIMIT" &&
        limitType == "ANY_OF_THEM" &&
        timeAmount.time === "" &&
        timeAmount.amount === ""
      ) {
        setTimeError("Please select limit.");
        errorCount++;
      }
      if (
        selectRadio === "LIMIT" &&
        limitType == "ANY_OF_THEM" &&
        timeAmount.amount === ""
      ) {
        setAmountError("Please enter valid amount.");
        errorCount++;
      }
      if (selectRadio === "LIMIT" && limitType == "TIME" && timeValue === "") {
        setTimeError("Please select limit.");
        errorCount++;
      }
      if (
        selectRadio === "LIMIT" &&
        limitType == "AMOUNT" &&
        amountValue === ""
      ) {
        console.log(limitType);
        setAmountError("Please enter valid amount.");
        errorCount++;
      }
      if (
        ["LIMIT"].includes(selectRadio) &&
        limitType == "ANY_OF_THEM" &&
        timeAmount.amount !== "" &&
        /* timeType === 'amount' && */ (!/^\d{0,10}(\d+)?$/.test(
          timeAmount.amount
        ) ||
          parseFloat(timeAmount.amount) <= 0)
      ) {
        console.log(limitType);
        setAmountError("Please enter valid amount.");
        errorCount++;
      }
      if (
        ["LIMIT"].includes(selectRadio) &&
        limitType == "AMOUNT" &&
        amountValue !== "" &&
        /* timeType === 'amount' && */ (!/^\d{0,10}(\d+)?$/.test(amountValue) ||
          parseFloat(amountValue) <= 0)
      ) {
        console.log(limitType);
        setAmountError("Please enter valid amount.");
        errorCount++;
      }
      if (!errorCount) {
        setSavePaymentMethod(true);
        const data = {
          days: `${
            limitType == "AMOUNT"
              ? ""
              : limitType == "TIME"
              ? timeValue
              : timeAmount.time
          }`,
          name: selectRadio,
          amount: `${
            limitType == "TIME"
              ? ""
              : limitType == "AMOUNT"
              ? amountValue
              : timeAmount.amount
          }`,
          limitType: limitType,
        };
        try {        
        const dataObject ={
            email: userInfo?.email,
            addressType:3,            
            parliament: foundItem?.id,
            action: "add",
            updateArray: allwalletData,
            referalReceiveType: data,
          }
          const response = await axios.post(`${process.env.REACT_APP_V2E}updateSettingsForParliament`, { data:dataObject });                
          setIsLoading(false);

        if (response?.data?.status === false) {
            showToast(response.data.message, ToastType.ERROR);
        }
        if (response?.data?.status === true) {          
          showToast("Update Wallet Successfully", ToastType.SUCCESS);
        }        
        } catch (error) {
          // @ts-ignore
          showToast(error.response.data.message, ToastType.ERROR);
        }

        console.log(data, "checkreferalReceiveType");
        setSavePaymentMethod(false);
      }
    } else if (selectRadio === "LIMIT" && limitType == "") {
      setTimeError("Please select One Check Box");
    }
    if (selectRadio === "IMMEDIATE" || selectRadio === "ONDEMAND") {
      if (!errorCount) {
        setSavePaymentMethod(true);        
        try {        
          const dataObject ={
              email: userInfo?.email,
              addressType:3,            
              parliament: foundItem?.id,
              action: "add",
              updateArray: allwalletData,
              referalReceiveType: {
                days: "",
                name: selectRadio,
                amount: "",
                limitType: "",
              },
            }
            const response = await axios.post(`${process.env.REACT_APP_V2E}updateSettingsForParliament`, { data:dataObject });                
            setIsLoading(false);
  
          if (response?.data?.status === false) {
              showToast(response.data.message, ToastType.ERROR);
          }
          if (response?.data?.status === true) {          
            showToast("Update Wallet Successfully", ToastType.SUCCESS);
          }        
          } catch (error) {
            // @ts-ignore
            showToast(error.response.data.message, ToastType.ERROR);
          }
        setSavePaymentMethod(false);
      }
    }    
  };


  const handleRemoveBox = async (index: number) => {
    const newBoxes = [...walletDetailsObj];
    // newBoxes.splice(index, 1);
    const foundItem = await passNameAndGetData(ActiveProject)    
    try {        
      const dataObject ={
          email: userInfo?.email,
          addressType:3,            
          parliament: foundItem?.id,
          action: "remove",
          updateArray: [newBoxes[index]],
          referalReceiveType: selectDBUserDetails?.referalReceiveType,
        }
        const response = await axios.post(`${process.env.REACT_APP_V2E}updateSettingsForParliament`, { data:dataObject });                
        setIsLoading(false);

      if (response?.data?.status === false) {
          showToast(response.data.message, ToastType.ERROR);
      }
      if (response?.data?.status === true) {          
        showToast("Update Wallet Successfully", ToastType.SUCCESS);
      }        
      } catch (error) {
        // @ts-ignore
        showToast(error.response.data.message, ToastType.ERROR);
      }

    newBoxes.splice(index, 1);
    setWalletDetailsObj(newBoxes);
  };


  return (
    <>
      <div>
      
          <SelectTextfield
            color="white"
            label={
              "Add your address to receive the converted collectible card "
            }
            name={"Add your address to receive the converted collectible card "}
            icon={"true"}
            iconColor={"white"}
            iconFunction={setTooltipShowCard}
          >
            <div
              className={`${
                window.screen.width > 350 ? "d-flex" : ""
              } w-100 text-uppercase`}
            >              

              {tooltipShowCard && (
                <div
                  style={{
                    display: "relative",
                  }}
                >
                  <div
                    className="newtooltip text-uppercase"
                    style={{
                      // right: "0%",
                      width: `${window.screen.width > 767 ? "50%" : "78%"}`,
                      marginLeft: `${window.screen.width > 767 ? "2.50%" : ""}`,
                      marginTop: `${window.screen.width > 767 ? "1%" : "1%"}`,
                    }}
                  >
                    {/* <p>Your CMP count</p> */}
                    <p
                      className="mt-1 lh-base"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      This address will be used in case you decide to convert
                      your card reward to a collectible card
                    </p>
                  </div>
                </div>
              )}

              <div className="form-inline-flex">
                <div className="col-field">
                  <input
                    style={{
                      padding: "12px 0px 12px 20px",
                      fontSize: `${
                        window.screen.width > 767 ? "12px" : "10px"
                      }`,
                      borderRadius: "5px",
                    }}
                    disabled
                    name="coin"
                    id="coin"
                    value={"Polygon chain".toLocaleUpperCase()}
                  />
                </div>
                <div className="col-field">
                  <input
                    className="text-uppercase"
                    style={{
                      padding: "12px 0px 12px 10px",
                      fontSize: `${
                        window.screen.width > 767 ? "12px" : "10px"
                      }`,
                      borderRadius: "5px",
                      marginBottom: "5px",
                    }}
                    disabled={!editCardAddress}
                    name="address"
                    type="address"
                    placeholder="Enter address"
                    value={CardDetails.address || ""}
                    onChange={(e) => {
                      handleChangeValue(e, "CardDetails");
                    }}
                  />
                  <div style={{ whiteSpace: "nowrap" }}>
                    {CardErrorValue?.walletError && (
                      <Errorsapn>{CardErrorValue?.walletError}</Errorsapn>
                    )}
                  </div>
                </div>

                {editCardAddress && (
                  <RemoveButton
                    type="button"
                    disabled={!CardDetails?.address || saveCardAddress}
                    style={{
                      marginLeft: "0px",
                      borderRadius: "5px",
                      fontSize: `${window.screen.width > 767 ? "12px" : "9px"}`,
                    }}
                    onClick={() => {                      
                      setAddType("CARDADDADDERS");                      
                      if (userInfo?.mfa == true) {
                        handleModleShow();
                      } else {
                        UpdateFunction("CARDADDADDERS");
                      }
                    }}
                  >
                    {saveCardAddress ? (
                      <span className="loading">ADD..</span>
                    ) : (
                      "ADD"
                    )}                    
                  </RemoveButton>
                 )}

                {!editCardAddress && (
                  <RemoveButton
                    type="button"
                    disabled={!CardDetails?.address || saveCardAddress}
                    style={{
                      marginLeft: "0px",
                      borderRadius: "5px",
                      fontSize: `${window.screen.width > 767 ? "12px" : "9px"}`,
                    }}
                    onClick={() => {
                      setEditCardAddress(true);
                    }}
                  >
                    {<span className="">EDIT</span>}
                  </RemoveButton>
                )}
              </div>
            </div>

            {CardErrorValue?.coinError && (
              <Errorsapn>{CardErrorValue?.coinError}</Errorsapn>
            )}            
          </SelectTextfield>  

          {/* {(userInfo?.isUserUpgraded || userInfo?.miningPass?.isActive )&& ( */}
          <SelectTextfield
            color="white"
            label={"ADD YOUR ADDRESS TO RECEIVE PAX REWARD"}
            name={"ADD YOUR ADDRESS TO RECEIVE PAX REWARD"}
            icon={"true"}
            iconColor={"white"}
            iconFunction={setTooltipShowPax}
          >
            <div
              className={`${
                window.screen.width > 350 ? "d-flex" : ""
              } w-100 text-uppercase`}
            >              
              {tooltipShowPax && (
                <div
                  style={{
                    display: "relative",
                  }}
                >
                  <div
                    className="newtooltip text-uppercase"
                    style={{
                      // right: "0%",
                      width: `${window.screen.width > 767 ? "50%" : "78%"}`,
                      marginLeft: `${window.screen.width > 767 ? "2.50%" : ""}`,
                      marginTop: `${window.screen.width > 767 ? "1%" : "1%"}`,
                    }}
                  >
                    {/* <p>Your CMP count</p> */}
                    <p
                      className="mt-1 lh-base"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      Pax rewards will be sent automatically to this address
                      every time you achieve the 100 CMP
                    </p>
                  </div>
                </div>
              )}

              <div className="form-inline-flex">
                <div className="col-field">
                  <input
                    style={{
                      padding: "12px 0px 12px 20px",
                      fontSize: `${
                        window.screen.width > 767 ? "12px" : "10px"
                      }`,
                      borderRadius: "5px",
                    }}
                    disabled
                    name="coin"
                    id="coin"
                    value={"BNB chain".toLocaleUpperCase()}
                  />
                </div>
                <div className="col-field">
                  <input
                    className="text-uppercase"
                    style={{
                      padding: "12px 0px 12px 10px",
                      fontSize: `${
                        window.screen.width > 767 ? "12px" : "10px"
                      }`,
                      borderRadius: "5px",
                      marginBottom: "5px",
                    }}
                    disabled={!editPaxAddress}
                    name="address"
                    type="address"
                    placeholder="Enter address"
                    value={paxDetails.address || ""}
                    onChange={(e) => {
                      handleChangeValue(e, "paxDetails");
                    }}
                  />
                  <div style={{ whiteSpace: "nowrap" }}>
                    {PaxErrorValue?.walletError && (
                      <Errorsapn>{PaxErrorValue?.walletError}</Errorsapn>
                    )}
                  </div>
                </div>
                {editPaxAddress && (
                  <RemoveButton
                    type="button"
                    disabled={!paxDetails?.address || savePaxAddress}
                    style={{
                      marginLeft: "0px",
                      borderRadius: "5px",
                      fontSize: `${window.screen.width > 767 ? "12px" : "9px"}`,
                    }}
                    onClick={() => {                      
                      setAddType("PAXADDADDERS");                      
                      if (userInfo?.mfa == true) {
                        handleModleShow();
                      } else {
                        UpdateFunction("PAXADDADDERS");
                      }
                    }}
                  >
                    {savePaxAddress ? (
                      <span className="loading">ADD..</span>
                    ) : (
                      "ADD"
                    )}                    
                  </RemoveButton>
                )}
                {!editPaxAddress && (
                  <RemoveButton
                    type="button"
                    disabled={!paxDetails?.address || savePaxAddress}
                    style={{
                      marginLeft: "0px",
                      borderRadius: "5px",
                      fontSize: `${window.screen.width > 767 ? "12px" : "9px"}`,
                    }}
                    onClick={() => {
                      setEditPaxAddress(true);
                    }}
                  >
                    <span className="">EDIT</span>
                  </RemoveButton>
                )} 
              </div>
            </div>

            {PaxErrorValue?.coinError && (
              <Errorsapn>{PaxErrorValue?.coinError}</Errorsapn>
            )}
            
          </SelectTextfield>

          {walletDetailsObj.length > 0 && (
          <SelectTextfield
            label={`${"ADD YOUR ADDRESSES TO RECEIVE THE REFERRAL PAYMENTS ".toLocaleUpperCase()}`}
            name="ADD YOUR ADDRESSES TO RECEIVE THE REFERRAL PAYMENTS "
            icon="true"
            color="white"
            iconColor={"white"}
            iconFunction={setTooltipShow}
          >
            {walletDetailsObj.length > 0 && tooltipShow && (
              <div
                style={{
                  display: "relative",
                }}
              >
                <div
                  className="newtooltip"
                  style={{
                    // right: "0%",
                    width: `${window.screen.width > 767 ? "50%" : "78%"}`,
                    marginLeft: `${window.screen.width > 767 ? "2.50%" : ""}`,
                    marginTop: `${window.screen.width > 767 ? "1%" : "1%"}`,
                  }}
                >
                  {/* <p>Your CMP count</p> */}
                  <p
                    className="mt-1 lh-base"
                    style={{
                      textAlign: "left",
                    }}
                  >
                    These addresses will be used to receive payments (50% of all
                    your friends' total purchases)
                  </p>
                </div>
              </div>
            )}

            {walletDetailsObj?.map((item, index) => {
              return (
                <>
                  <div
                    className={`${
                      window.screen.width > 350 ? "" : ""
                    } mt-3 form-inline-flex flex-auto py-2`}
                    key={index}
                  >
                    <div className="col-field">
                      <input
                        name="coin"
                        id="coin"
                        style={{
                          padding: "12px 0px 12px 20px",
                          fontSize: `${
                            window.screen.width > 767 ? "12px" : "10px"
                          }`,
                          borderRadius: "5px",
                        }}
                        disabled={true}
                        value={item?.coin.toLocaleUpperCase()=="MATIC"?"POLYGON":(item?.coin.toLocaleUpperCase() || "")}
                        onChange={(e) => {
                            handleChangeValue(e, "")
                        }}
                      />
                    </div>

                    <div className="col-field">
                      <input
                        style={{
                          padding: "10px 0px 10px 10px",
                          fontSize: `${
                            window.screen.width > 767 ? "12px" : "10px"
                          }`,
                          borderRadius: "5px",
                        }}
                        disabled={true}
                        name="address"
                        type="address"
                        placeholder="Enter address"
                        value={item?.address || ""}
                        onChange={(e) => {
                          handleChangeValue(e, "walletDetails");
                        }}
                      />
                    </div>

                    <RemoveButton
                      style={{
                        marginLeft: "0px",
                        borderRadius: "5px",
                        fontSize: `${window.screen.width > 767 ? "" : "19px"}`,
                      }}
                      onClick={() => {
                        handleRemoveBox(index);
                      }}
                    >
                      -
                    </RemoveButton>
                  </div>
                </>
              );
            })}
          </SelectTextfield>
        )}
        {coinList.length > 0 && (
          <SelectTextfield
            label={`${
              walletDetailsObj.length < 1
                ? "ADD YOUR ADDRESSES TO RECEIVE THE REFERRAL PAYMENTS"
                : ""
              }`}
            color="white"
            name={`${
              walletDetailsObj.length < 1 &&
              "ADD YOUR ADDRESSES TO RECEIVE THE REFERRAL PAYMENTS"
            }`}
            icon="true"
            iconColor={"white"}
            iconFunction={setTooltipShow}
          >
            {walletDetailsObj.length < 1 && tooltipShow && (
              <div>
                <div
                  className="newtooltip"
                  style={{
                    width: `${window.screen.width > 767 ? "50%" : "78%"}`,
                    marginLeft: `${window.screen.width > 767 ? "2.50%" : ""}`,
                    marginTop: `${window.screen.width > 767 ? "1%" : "1%"}`,
                  }}
                >
                  {/* <p>Your CMP count</p> */}
                  <p
                    className="mt-1 lh-base"
                    style={{
                      textAlign: "left",
                    }}
                  >
                    These addresses will be used to receive payments (50% of all
                    your friends' total purchases)
                  </p>
                </div>
              </div>
            )}
            {walletDetailsObj.length < 1 && <></>}

            <div
              className={`${
                window.screen.width > 350 ? "" : ""
              } form-inline-flex flex-auto`}
            >
              <div className="col-field">
                <select
                  name="coin"
                  id="coin"
                  style={{
                    width: "100%",
                    padding: "12px 0px 12px 10px",
                    fontSize: `${window.screen.width > 767 ? "12px" : "10px"}`,
                    borderRadius: "5px",
                  }}
                  value={walletDetails?.coin.toLocaleUpperCase() || ""}
                  onChange={(e) => {
                    handleChangeValue(e, "walletDetails");
                  }}
                >
                  <option value="" className="text-uppercase">
                    {"Choose chain".toLocaleUpperCase()}
                  </option>
                  {coinList.map((item: any, index: number) => {
                    return (
                      <option
                        className="text-uppercase"
                        key={index}
                        value={item.symbol}
                        id={item.id}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {errorValue?.coinError && (
                  <Errorsapn>{errorValue?.coinError}</Errorsapn>
                )}
              </div>
              <div className="col-field">
                <input
                  style={{
                    width: "100%",
                    padding: "12px 0px 12px 10px",
                    fontSize: `${window.screen.width > 767 ? "12px" : "10px"}`,
                    borderRadius: "5px",
                    marginBottom: "5px",
                  }}
                  name="address"
                  type="address"
                  placeholder={"Enter address".toLocaleUpperCase()}
                  value={walletDetails.address || ""}
                  onChange={(e) => {
                    handleChangeValue(e, "walletDetails");
                  }}
                />
                <div style={{ whiteSpace: "nowrap" }}>
                  {errorValue?.walletError && (
                    <Errorsapn>{errorValue?.walletError}</Errorsapn>
                  )}
                </div>
              </div>
              <RemoveButton
                type="button"
                disabled={
                  (!walletDetails?.address && !walletDetails?.coin) ||
                  saveAddress
                }
                style={{
                  marginLeft: "0px",
                  borderRadius: "5px",
                  fontSize: `${window.screen.width > 767 ? "" : "18px"}`,
                  backgroundColor: `${
                    walletDetails?.address || walletDetails?.coin ? "" : "gray"
                  }`,
                }}
                onClick={() => {
                  updateAddress();
                  // setAddType("ADDADDERS")
                  // handleModleShow()
                }}
              >
                {saveAddress ? <span className="loading">+</span> : "+"}
              </RemoveButton>
            </div>
          </SelectTextfield>
        )}
        <SelectTextfield
          label={`${"Choose your preferred payment time".toLocaleLowerCase()}`}
          name="Choose your preferred payment time"
          icon="true"
          color="white"
          iconColor={"white"}
          iconFunction={setTooltipShow2}
        >
          {tooltipShow2 && (
            <div
              style={{
                display: "relative",
              }}
            >
              <div
                className="newtooltip"
                style={{
                  // right: "0%",
                  width: `${window.screen.width > 767 ? "50%" : "78%"}`,
                  marginLeft: `${window.screen.width > 767 ? "2.50%" : ""}`,
                  marginTop: `${window.screen.width > 767 ? "1%" : "1%"}`,
                }}
              >
                <p
                  className="mt-1 lh-base"
                  style={{
                    textAlign: "left",
                  }}
                >
                  Be aware that the network fee will be deducted from the
                  amount, so choose wisely
                </p>
              </div>
            </div>
          )}

          <div className="w-100">
            <div className="d-flex  justify-content-start align-items-center ">
              <Form.Check
                style={{ fontSize: "20px", marginRight: "10px" }}
                type="radio"
                id={`immediate`}
                checked={selectRadio === "IMMEDIATE"}
                disabled={true}
                onClick={(e) => {
                  hideError();
                  setSelectRadio("IMMEDIATE");
                }}
              />
              <label htmlFor="immediate">
                {"Immediate".toLocaleUpperCase()}
              </label>
            </div>

            <div className="mt-3 ">
              <div className="d-flex align-items-center">
                <Form.Check
                  style={{ fontSize: "20px", marginRight: "10px" }}
                  type="radio"
                  id={`limit`}
                  checked={selectRadio == "LIMIT"}
                  disabled={true}
                  onClick={(e) => {
                    // setDefaultValue();
                    setSelectRadio("LIMIT");
                  }}
                />
                <label htmlFor="By limit">
                  {"By limit".toLocaleUpperCase()}
                </label>
              </div>

              {/* Limit part */}

              {selectRadio === "LIMIT" && (
                <>
                  <div className="d-flex" style={{ marginLeft: "2em" }}>
                    <div className=" d-flex  align-items-center">
                      <Form.Check
                        style={{ fontSize: "20px", marginRight: "10px" }}
                        type="checkbox"
                        id={`Time`}
                        checked={limitType == "TIME"}
                        onChange={() => {
                          setLimitType("TIME");
                          setTimeError("");
                        }}
                      />
                      <label
                        htmlFor="default-checkbox"
                        style={{ marginRight: "20px" }}
                      >
                        {" "}
                        {"Time".toLocaleUpperCase()}{" "}
                      </label>
                    </div>
                    <div className=" d-flex  align-items-center">
                      <Form.Check
                        style={{ fontSize: "20px", marginRight: "10px" }}
                        type="checkbox"
                        id={`Amount`}
                        checked={limitType == "AMOUNT"}
                        onChange={() => {
                          setLimitType("AMOUNT");
                          setTimeError("");
                        }}
                      />
                      <label
                        htmlFor="default-checkbox"
                        style={{ marginRight: "20px" }}
                      >
                        {" "}
                        {"Amount".toLocaleUpperCase()}{" "}
                      </label>
                    </div>
                    <div className=" d-flex  align-items-center">
                      <Form.Check
                        style={{ fontSize: "20px", marginRight: "10px" }}
                        type="checkbox"
                        id={`AnyofThem`}
                        checked={limitType == "ANYOFTHEM"}
                        onChange={() => {
                          setLimitType("ANYOFTHEM");
                          setTimeError("");
                        }}
                      />
                      <label
                        htmlFor="default-checkbox"
                        style={{ marginRight: "7px" }}
                      >
                        {" "}
                        {"Any of Them".toLocaleUpperCase()}{" "}
                      </label>
                    </div>
                  </div>
                  {limitType == "TIME" && (
                    <div
                      className={`${
                        window.screen.width > 350 ? "d-flex " : ""
                      }mt-2`}
                      style={{ marginLeft: "2em" }}
                    >
                      <select
                        className="color-back"
                        style={{
                          width: "15em",
                          height: "40px",
                          color: "black",
                          paddingLeft: "10px",
                          borderRadius: "0px 5px 5px 0px",
                        }}
                        defaultValue={timeType}
                        value={timeValue.toLocaleUpperCase()}
                        onChange={(e) => {
                          // setTimeAmount({ ...timeAmount, time: e.target.value })
                          setTimeValue(e.target.value);
                        }}
                      >
                        <option value="">
                          {"Select time frame".toLocaleUpperCase()}
                        </option>
                        <option value="1 DAY">1 Day</option>
                        <option value="1 WEEK">1 Week</option>
                        <option value="1 MONTH">1 Month</option>
                      </select>
                    </div>
                  )}
                  {limitType == "AMOUNT" && (
                    <>
                      <input
                        style={{
                          width: "15em",
                          padding: "10px 0px 10px 10px",
                          fontSize: `${
                            window.screen.width > 767 ? "12px" : "10px"
                          }`,
                          borderRadius: "5px",
                          paddingLeft: "10px",
                          marginLeft: "2em",
                        }}
                        className="mt-2"
                        maxLength={10}
                        type="text"
                        name=""
                        id=""
                        placeholder={"Type amount".toLocaleUpperCase()}
                        value={amountValue}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setAmountError("");
                            setTimeError("");
                            setAmountValue(e.target.value);
                          }
                        }}
                      />
                      {/* <div className="d-flex mt-2 justify-content-between" style={{ marginLeft: '2em' }}>
                                        {amountError && <Errorsapn>{amountError}</Errorsapn>}
                                    </div> */}
                    </>
                  )}

                  {limitType == "ANYOFTHEM" && (
                    <>
                      <div
                        className={`${
                          window.screen.width > 350 ? "d-flex " : ""
                        } mt-2`}
                        style={{ marginLeft: "2em" }}
                      >
                        <select
                          className="color-back"
                          style={{
                            width: "15em",
                            height: "40px",
                            color: "black",
                            paddingLeft: "10px",
                            borderRadius: "0px 5px 5px 0px",
                          }}
                          defaultValue={timeType}
                          value={timeAmount?.time}
                          onChange={(e) => {
                            setTimeAmount({
                              ...timeAmount,
                              time: e.target.value,
                            });
                          }}
                        >
                          <option value="">
                            {"Select time frame".toLocaleUpperCase()}
                          </option>
                          <option value="1 DAY">1 DAY</option>
                          <option value="1 WEEK">1 WEEK</option>
                          <option value="1 MONTH">1 MONTH</option>
                        </select>
                        <div
                          style={{
                            width: window.screen.width < 350 ? "15em" : "auto",
                            padding: "1em",
                            textAlign: "center",
                          }}
                        >
                          OR
                        </div>
                        <input
                          style={{
                            width: "15em",
                            padding: "10px 0px 10px 10px",
                            fontSize: `${
                              window.screen.width > 767 ? "12px" : "10px"
                            }`,
                            borderRadius: "5px",
                            paddingLeft: "10px",
                          }}
                          maxLength={10}
                          type="text"
                          name=""
                          id=""
                          placeholder={"Type amount".toLocaleUpperCase()}
                          value={timeAmount?.amount}
                          onChange={(e) => {
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              setAmountError("");
                              setTimeError("");
                              setTimeAmount({
                                ...timeAmount,
                                amount: e.target.value,
                              });
                            }
                          }}
                        />
                      </div>
                      {/* <div className="d-flex mt-2 justify-content-between" style={{ marginLeft: '2em' }}>
                                        {timeError && <Errorsapn>{timeError}</Errorsapn>}
                                        {amountError && <Errorsapn>{amountError}</Errorsapn>}
                                    </div> */}
                    </>
                  )}
                  <div
                    className="d-flex mt-2 justify-content-between"
                    style={{ marginLeft: "2em" }}
                  >
                    {timeError && <Errorsapn>{timeError}</Errorsapn>}
                    {amountError && <Errorsapn>{amountError}</Errorsapn>}
                  </div>
                </>
              )}              
            </div>

            <div className="mt-3 ">
              <div className="d-flex align-items-center">
                <Form.Check
                  style={{ fontSize: "20px", marginRight: "10px" }}
                  type="radio"
                  id={`demand`}
                  checked={selectRadio == "ONDEMAND"}
                  onClick={(e) => {
                    // setDefaultValue();
                    setSelectRadio("ONDEMAND");
                  }}
                />
                <label htmlFor="On demand">
                  {"On demand".toLocaleUpperCase()}
                </label>
              </div>
              {/* {selectRadio === "ONDEMAND" &&
                userInfo?.referalReceiveType?.name === "ONDEMAND" && (
                )} */}
            </div>
          </div>
        </SelectTextfield>
        {/* )} */}   
        <div className="d-flex justify-content-center">
          <div
            className="d-flex justify-content-center"
            style={{
              width: `${window.screen.width > 767 ? "34%" : ""}`,
              margin: "0px 0px 15px 0px",
            }}
          >
            <Buttons.Primary
              disabled={!selectRadio || savePaymentMethod}              
              type="button"
              style={{
                maxWidth: "200px",
                // @ts-ignore
                backgroundColor: `${
                  selectDBUserDetails?.referalReceiveType?.name !== selectRadio ||
                  selectRadio == "LIMIT" ||
                  // @ts-ignore
                  selectDBUserDetails?.wellDAddress?.length != walletDetailsObj?.length
                    ? ""
                    : "gray"
                }`,
              }}
              onClick={() => {
                // selectSendType()
                // @ts-ignore
                if (
                  selectDBUserDetails?.referalReceiveType?.name !== selectRadio ||
                  selectRadio == "LIMIT" ||
                  // @ts-ignore
                  selectDBUserDetails?.wellDAddress?.length != walletDetailsObj?.length
                ) {
                  setAddType("UPDATESETTING");
                  // handleModleShow()
                  if (selectDBUserDetails?.mfa == true) {
                    handleModleShow();
                  } else {
                    UpdateFunction("UPDATESETTING");
                  }
                }
              }}
            >
              {savePaymentMethod ? (
                <span className="loading"> UPDATE...</span>
              ) : (
                "UPDATE"
              )}              
            </Buttons.Primary>
          </div>
        </div>   
      </div>
      {!userInfo?.mfa && (
        <div>
          <div className="d-flex justify-content-center">
            <hr
              style={{
                color: "rgb(99,82,232)",
                border: "1px solid",
                width: "25%",
              }}
            />
          </div>
          <div className="justify-content-center" style={{ display: "grid" }}>
            <SelectTextfield name="Stay on the safe side">
              <span className="text-white">
                STAY ON THE SAFE SIDE
                <span
                  className="bi bi-info-circle"
                  style={{ padding: "10px" }}
                  onMouseDown={(e) => {
                    setTooltipShow5(false);
                  }}
                  onMouseUp={(e) => {
                    setTooltipShow5(true);
                  }}
                  onMouseEnter={() => setTooltipShow5(true)}
                  onMouseLeave={() => setTooltipShow5(false)}
                ></span>{" "}
              </span>
              {tooltipShow5 && (
                <div>
                  <div
                    className="newtooltip"
                    style={{
                      width: `${window.screen.width > 767 ? "180%" : "180%"}`,
                      marginLeft: `${
                        window.screen.width > 767 ? "-150%" : "-123%"
                      }`,
                      marginTop: `${window.screen.width > 767 ? "-118%" : "-125%"}`,
                    }}
                  >
                    {/* <p>Your CMP count</p> */}
                    <p
                      className="mt-1 lh-base"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      We strongly advise activating two-factor authentication
                      (2FA) for enhanced security. With 2FA, your account's
                      security is bolstered, ensuring that even if someone
                      guesses your password, they won't gain access to your
                      account. Please note that we cannot be held responsible
                      for any pending funds.
                    </p>
                  </div>
                </div>
              )}
              {/* <div className=''> */}

              {/* </div> */}
            </SelectTextfield>
            <Buttons.Primary
              className="TwoFA"
              onClick={() => {
                navigate("/profile/password");
              }}
            >
              ENABLE 2FA
            </Buttons.Primary>
          </div>
        </div>
      )}
      <div>
        <Modal
          className=""
          show={modleShow}
          onHide={handleModleClose}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ backgroundColor: "rgb(0 0 0 / 80%)", zIndex: "2200" }}
          // @ts-ignore
          // contentClassName={"modulebackground ForBigNft"}
        >
          <div
            className="d-flex justify-content-end"
            style={{
              color: "gray",
              cursor: "pointer",
            }}
            onClick={() => {
              handleModleClose();
            }}
          >
            X
          </div>
          <Modal.Body>
            <div className="d-flex flex-column align-items-center">
              <WalletValidation
                setMfaLogin={setMfaLogin}
                UpdateFunction={UpdateFunction}
                modalOpen={true}
                addType={addType}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default WalletSetting