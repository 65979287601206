import React, { useContext, useEffect, useState } from 'react'
import VBG from '../../../assets/images/VBG.png';
import VBGM from '../../../assets/images/VBGM.png';
import UserContext from 'Contexts/User';
import { useNavigate } from 'react-router-dom';
import { H2 } from './VotingPayment';
import SmallBackArrow from '../../../Components/icons/SmallBackArrow';
import { ButttonDiv } from '../VotingBooster';
import {SendTransactionRequest, useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";
import { auth, db, firestore } from "../../../firebase";
import axios from 'axios';
import AppContext from 'Contexts/AppContext';
import { ToastType } from 'Contexts/Notification';
import { showToast } from 'App';
// import {beginCell, Cell} from 'ton'
import {Cell} from 'ton'


type TRONWalletPropes = {
  payamount:any
  VTEAmount:any
  payType:any
  miniDays:any
  extraVote:any
  votePackageId:any
  setIsLoading:any
  setPaymentStatus:any
  setShowText:any
  setPayButton:any 
  coinInfo:any
};
function TRONWallet({
  payamount,
  VTEAmount,
  payType,
  miniDays,
  extraVote,
  votePackageId,
  setIsLoading,
  setPaymentStatus,
  setShowText,
  setPayButton, coinInfo }:TRONWalletPropes) {
  const { user, userInfo } = useContext(UserContext); 
//  const wallet = useTonWallet();  
 const [tonConnectUi] = useTonConnectUI();  
 const [trxAmount, setTrxAmount] = useState(0);
//  const {getSpinData } = useContext(AppContext);

 const [tronWeb, setTronWeb] = useState(null);
 const [account, setAccount] = useState(null);
 const [balance, setBalance] = useState(0);
 const [isConnected, setIsConnected] = useState(false);
 const [recipientAddress, setRecipientAddress] = useState('TEEEo69tr9BbHCSXy2LbpM2bWAkXpNvBK5');
//  const [amount, setAmount] = useState(1);
 const [network, setNetwork] = useState('');


 const fetchTonPrice = async () => {
  try {
    const response = await axios.get(
      'https://api.coingecko.com/api/v3/simple/price?ids=tron&vs_currencies=usd'
    );
    const price = response.data.tron.usd;    
    setTrxAmount(price);
    return price
  } catch (error) {
    console.error('Error fetching TON price:', error);
  }  
};


// Fetch TON price when the component is mounted

useEffect(() => {
  fetchTonPrice();
  // initTronWeb()
  // @ts-ignore
}, []);



 const payNow = (detail?: any) => {

  const headers = {
    // 'Content-Type': 'application/json',
    "accept": "application/json",
    // @ts-ignore
    "Authorization": `Bearer ${auth?.currentUser?.accessToken}`,
    "content-type": "application/json"
  }  
  const data = {
    userId: `${user?.uid}`,
    userEmail: `${user?.email}`,
    walletType: `wallet connect`,
    amount: Number(payamount && Number(payamount)) / (trxAmount || 5),
    dollarAmount: Number(payamount),
    VTEAmount: Number(VTEAmount),        
    network: `728126428`,    
    origincurrency: `TRX`,
    token: `TRX`,
    event: 'Approved',
    transactionType: payType,
    numberOfVotes: extraVote,
    paymentDetails: detail,
    votePackageId:votePackageId,
    miningPassDurationInDays:miniDays,
  }
  console.log("afterPayment Data : ", data)
  axios.post(`${process.env.REACT_APP_API}/payment/update/user/afterPayment`, data,
    {
      headers: headers
    }).then(async (response) => {
      // setApiCalling(false)
      // console.log(,"response.data.data")
      console.log(response.data, "response.data")
      // transactionId.current = response.data
      setIsLoading(false)
      setPaymentStatus({ type: "success", message: "" })
      setShowText(false)
      setPayButton(false);
      // getSpinData(userInfo?.uid)
      // setShowForWait(true)
    })
    .catch((error) => {
      // setPaymentStatus({ type: 'error', message: '' });
      console.log(error, "response.data")
      setIsLoading(false)
      setPaymentStatus({ type: "error", message: "We apologize for the inconvenience. Please ensure that you have a stable internet connection and try again." })
      setShowText(false)
      setPayButton(false);
    })
}


const initTronWeb = async () => {
  // @ts-ignore
  if (window?.tronWeb && window?.tronWeb?.ready) {
    // @ts-ignore
    setTronWeb(window.tronWeb);
    // @ts-ignore
    setAccount(window?.tronWeb?.defaultAddress?.base58 || "123");   
    // @ts-ignore
    setIsConnected(window?.tronWeb?.ready?true:false)   
    // @ts-ignore
    const fullNodeUrl = window.tronWeb.fullNode.host;
    if (fullNodeUrl.includes('trongrid.io')) {
      if (fullNodeUrl.includes('shasta')) {
        setNetwork('Shasta Testnet');
      } else {
        setNetwork('Mainnet');
      }
    } else {
      setNetwork('Private Network');
    }
  } else {
    console.log('TronLink is not installed or not ready');
  }
};

const requestTronLinkConnection = async () => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  setIsLoading(true);
  // @ts-ignore
  if (window?.tronWeb) {
    try {
      // @ts-ignore
      const result = await window?.tronLink.request({ method: 'tron_requestAccounts' });
      if (result.code === 200) {        
        initTronWeb();
      } else {
        console.error('TronLink connection failed:', result.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error requesting TronLink connection:', error);
      setIsLoading(false);
      setPaymentStatus({ type: "error", message: `Error requesting TronLink connection:${error}`})  
    }
  }
  // else if (isMobile) {
  //   try {
      
  //   } catch (error) {
  //     console.error('Mobile connection error:', error);
  //     setPaymentStatus({ type: "error", message: "Failed to open TronLink" });
  //   }
  //   setIsLoading(false);
  // }
  
  else {
    console.log('TronLink is not installed');
    setIsLoading(false);
    // alert('Please install TronLink to connect your wallet.');
    setPaymentStatus({ type: "error", message: "Please install TronLink to connect your wallet." })
  }
};
const handleTransfer = async () => {
setIsLoading(true)
  // @ts-ignore
  if (!window?.tronWeb || !window?.tronWeb?.defaultAddress?.base58 || !recipientAddress || !payamount) {    
    requestTronLinkConnection()
    return;
  }

  try {    
if(coinInfo?.Type == "USDT"){
  const amountInSun = payamount * 1e6;

  // @ts-ignore
  const contractValue = await tronWeb.contract().at(coinInfo?.ContractAddress);
  const usdtBalanceUnits = await contractValue.balanceOf(account).call();
  const usdtBalance = usdtBalanceUnits / 1e6;
    console.log(usdtBalance,"usdtBalanceusdtBalance")
  if (usdtBalance < payamount) {
    setPaymentStatus({ type: "error", message: "Insufficient USDT balance" })
    setIsLoading(false)
    return
  }    
    // Call the `transfer` function of the USDT contractValue
    const PayWithUsdt = await contractValue.transfer(recipientAddress, amountInSun).send();
    // Get the transaction hash
    const txnHash = PayWithUsdt.transaction.txID;
    if(txnHash){
      payNow({...PayWithUsdt?.transaction,hash:txnHash, orderId: `VTE-TRX-${txnHash?.substring(0, 4)}` })
    }
    console.log(PayWithUsdt,"PayWithUsdtPayWithUsdt")
}else{
  
  // @ts-ignore
  const trxBalanceSun = await tronWeb.trx.getBalance(account);
  // @ts-ignore
  const trxBalanceTrx = tronWeb.fromSun(trxBalanceSun); // Convert from SUN to TRX
  console.log(trxBalanceTrx,"usdtBalanceusdtBalance")
  if (trxBalanceTrx < payamount) {
    setPaymentStatus({ type: "error", message: "Insufficient balance" })
    setIsLoading(false)
    return
  }   
  // @ts-ignore
  const sunAmount = tronWeb.toSun(payamount);
  // @ts-ignore
  const transaction = await tronWeb.trx.sendTransaction(recipientAddress, sunAmount);
  const transactionId = transaction.txid;    
  console.log(transaction,"transactionIdtransactionId")
  if(transactionId){
    payNow({...transaction,hash:transactionId, orderId: `VTE-TRX-${transactionId?.substring(0, 4)}` })
  }    
}
    // setPaymentStatus({ type: "success", message: "Transaction successful!"})        
  } catch (error) {
    setIsLoading(false)
    console.error('Transaction failed:', error);
    // @ts-ignore
    // alert('Transaction failed' + error);
    setPaymentStatus({ type: "error", message: "Transaction failed" })
  }
};
// useEffect(() => {
//   const interval = setInterval(() => {    
//     // @ts-ignore
//     const fullNodeUrl = window.tronWeb.fullNode.host;
//     // @ts-ignore
//     const wallet =window?.tronWeb?.ready
//     let selectNetwork= ""
//     if (fullNodeUrl && fullNodeUrl.includes('trongrid.io')) {
//       if (fullNodeUrl.includes('shasta')) {
//         selectNetwork='Shasta Testnet'        
//       } else {
//         selectNetwork='Mainnet'
//       }   
//       setIsConnected(wallet?true:false)   
//     } else {
//       selectNetwork='Private Network'     
//       setIsConnected(wallet?true:false)
//     }              
//     // if(selectNetwork !=network){
//     //   initTronWeb()
//     // }    
//     // @ts-ignore
//     setTronWeb(window.tronWeb);
//     // @ts-ignore
//     setAccount(window?.tronWeb?.defaultAddress?.base58);   
//     // @ts-ignore
//     setIsConnected(window?.tronWeb?.ready?true:false)  
//     // setIsConnected(wallet?true:false)
//   }, 2000);
  
//   return () => clearInterval(interval);
//   // @ts-ignore
// },[window?.tronWeb])

  return (
    <>
    <div>
      {/* @ts-ignore  */}
      {isConnected ?
              <div
        className={`${window.screen.width > 767 ? "" : "mt-3"} d-flex justify-content-center`}
      >
        <ButttonDiv className="mt-1">
          <button                        
            onClick={() => {
              if(process.env.REACT_APP_SITE_URL?.includes("staging")){
                if(network=="Shasta Testnet" && coinInfo.Type=="USDT"){
                  setPaymentStatus({ type: "error", message: "You can't pay with Testnet. Please switch to the Mainnet in your wallet." })
                  return
                }else{
                  handleTransfer()     
                }
              }
              if(!process.env.REACT_APP_SITE_URL?.includes("staging")){
                if(network=="Shasta Testnet"){
                  setPaymentStatus({ type: "error", message: "You can't pay with Testnet. Please switch to the Mainnet in your wallet." })
                  return
              }else{
                handleTransfer()           
              }
            }
            }}
          >

            PAY NOW!
          </button>
        </ButttonDiv>
      </div > 
      :
      <div
      className={`${window.screen.width > 767 ? "" : "mt-3"} d-flex justify-content-center`}
    >
      <ButttonDiv className="mt-1">
        <button           
          onClick={() => {
            // tonConnectUi.openModal()          
            requestTronLinkConnection()        
          }}
        >
           CONNECT WALLET
        </button>
      </ButttonDiv>
    </div >
      }
{/* @ts-ignore  */}
  {isConnected && <>
        {coinInfo.Type=="USDT"&& network=="Shasta Testnet" && <div className='text-center mt-2'      >
            <p>You can't pay with Testnet. Please switch to the Mainnet in your wallet.</p>
        </div>}
        {!process.env.REACT_APP_SITE_URL?.includes("staging") && network=="Shasta Testnet" && <div className='text-center mt-2'      >
            <p>You can't pay with Testnet. Please switch to the Mainnet in your wallet.</p>
        </div>}
  </>}
    </div>
    </>
  )
}

export default TRONWallet
